import Slide1 from '../assets/img/carousel/slide1.jpg';
import imgAbout from '../assets/img/retrato/aboutMe.jpg';
import imgAbout2 from '../assets/img/retrato/aboutMe.PNG';

/** Main Carousel Data */
export const ImgDataMe = [
  {
    id: 1,
    img: imgAbout,
  },
  {
    id: 2,
    img: imgAbout2,
  },

]